.login-container{
    padding: 0 50px;
    margin-top: 140px;
    .solid-button{
        text-transform: initial;
    }
    .login-form{
        width:  ~"calc((100% / 2) - 110px)";
        border-radius: 5px;
        box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.3);
        background-color: #ffffff;
        .input-wrapper{
            padding-left:10px; 
            height: 50px;
            color: #4a4a4a;
            font-family: "Montserrat";
            font-size: 15px;
            font-weight: 600;
        }
        .sign-in{
            padding: 43px 60px 0 60px;
            position: inherit;
            width:100%;
            top:58px;
           
            .supplier-button{
                height: 50px;
            }
            .login-step2-title{
                font-family: "Montserrat";
                font-size: 15px;
                font-weight: 600;
                text-align: center;
                .facebook{
                    color: #2d2d94
                }
                .google{
                    color:#3d3d9e;
                }
            }
            input{
                &::placeholder{
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 600;
                    color: #4a4a4a;
                }  
            }
            .forgot-pass{
                font-family: "Montserrat";
                font-size: 15px;
                font-weight: 500;
                color: #000000;
                text-decoration: underline;
                display: block;
                margin-bottom: 20px;
            }
            .login-title{
                text-align: center;
                font-family: "Montserrat";
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 21px;
            }
            
        }
        .register-step2{
            .sign-in;
            .conditions{
                font-family: "Montserrat";
                font-size: 10px;
                font-weight: 500;
                color: #4a4a4a;
            }
            .more-validation{
                .break-line{
                    height: 1px;
                    background: #999999;
                    margin: 20px 0;
                }
                input{
                    padding-left: 10px !important;
                }
            }
            .register-step2-title{
                font-family: "Montserrat";
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 39px;
            }
            .currency, .organisation{
                padding-left:0px !important
            }
            .phone-number{
                display: flex;
                .input:nth-child(1){
                    width:25%;
                }
                .input:nth-child(2){
                    width: 75%;
                }
            }
            .phone-number, .currency, .organisation{
                height: 50px;
                .ng-select {
                    height: 100%;
                    width: 100%;
                    z-index: auto;
                    .ng-select-container {
                    border: 0 !important;
                    height: 100%;
                    }
                }
                .ng-arrow{
                    display: none;
                }
                .ng-value{
                    padding-left:0;
                    margin-top:0; 
                }
                &.add-arrow{
                    color: #4a4a4a;
                }
                select{
                    border: 0;
                    width: 100%;
                    height: 100%;
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 600;
                    color: #4a4a4a;
                    &.country-code{
                        background-color: rgba(225, 225, 225, 0.33);
                        width:90px;
                    }
                    &.currency-code, &.organisation-type,&.country-code{
                        -webkit-appearance: none;
                    }
                }
                input{
                    height: 100%;
                    border:0;
                    border-left:solid 1px rgba(155, 155, 155, 0.39);
                    padding-left: 10px;
                    &.code{
                        width: 25%;
                    }
                    &.number{
                        width: 75%;
                    }
                }
            }
        }
       
    }   
 }
