
.homePage{
    .reviews-desc{
        font-family: "Montserrat";
        font-size: 22px;
        text-align: center;
        margin-bottom: 60px;
    }
    .title-desc{
        text-align: center;
        text-transform: uppercase;
        font-family: "Montserrat";
        font-size: 25px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 21px;
    }
    .main-banner{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .common-img-bg {
        display: flex;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-image: url(https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/SupplierPortal3-min-min.efe637dcea3e7110c06f-min.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .inner-box{
            position: absolute;
            padding: 0 30px;
            width: 100%;
            height:auto;
            top:25%;
            .formula-label{
                color: #000000;
                font-weight: 600;
                font-size: 17px
            }
        .supplierHome-form{
            background-color: rgba(255, 255, 255, 0.82);
            height: auto;
            max-width: 400px;
            margin-left: auto;
            margin-right: 75px;
            padding: 25px 30px;
            .form-title{
                font-family: "Montserrat";
                font-size:21px;
                font-weight: 600;
                color: #000000;
                margin-bottom: 19px;
            }
            .input-wrapper{
                padding-left:0px; 
                margin-bottom: 15px;
            }
            .rentals-nbr{
                padding-left: 10px;
                width:100%;
                height:100%;
                border-radius: 4px;
                border: 1px solid rgba(151, 151, 151, 0.3);
                // opacity: 0.69;
            }
            .rentals-formula{
                -webkit-appearance: none;
                width:100%;
                height:100%;
                border-radius: 4px;
                border: 1px solid rgba(151, 151, 151, 0.3);
                // opacity: 0.69;
                .select-option{
                    font-family: "Montserrat";
                    font-size:17px;
                    color:rgba(151, 151, 151, 0.3);
                }
                .ng-select-container{
                    height: 100%;
                    border: 0px;
                    .ng-value-container{
                        padding-left:0px;
                    }
                }
            }
            .revenue-range{
                font-family: "Arial";
                font-size: 28px;
                margin:0;
                letter-spacing: 0.5px;
                line-height: 1;
                color: #000000;
            }
            .revenue-text{
                font-family: "Montserrat";
                font-size: 17px;
                font-weight: 600;
                color: #000000;
            }
            .form-button{
                color: #f3f3f3;
                font-family: "Montserrat";
                font-size: 18px;
                font-weight: 600;
                text-transform: initial;
                max-height:55px
            }
        }
        }
    }
    .supplier-intro{
        margin-top:120px;
        .supplier-desc{
            padding: 0 40px;
            .text-desc{
                font-family: "Montserrat";
                font-size: 19px;
                line-height: 28px;
                text-align: center;
            }
        }
        .supplier-advantage{
            padding: 0 40px;
            margin-top:110px;
            .advantage{
                width: ~"calc((100% - 110px)/3)";
                .cash-icon{
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 94px;
                    height:70px;
                    margin:auto;
                    background-position: -12px -10px;
                }
                .closet-icon{
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 56px;
                    height:70px;
                    margin:auto;
                    background-position: -210px -10px;
                }
                .heart-icon{
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 78px;
                    height:69px;
                    margin:auto;
                    background-position: -116px -10px;
                }
                .advantage-title{
                    font-family: "Montserrat";
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;
                    text-transform: uppercase;
                }
                .advantage-text{
                    font-family: "Montserrat";
                    font-size: 15px;
                    line-height: 20px;
                    text-align: center;
                }
            }
        }
        
    }
    .policies{
        margin-top:100px;
        .policy-desc{
            .title-desc{
                margin-bottom:48px;
            }
            .image-text{
                width:100%;
                height:auto;
                padding: 0 40px;
                .policy-image{
                    width:475px;
                    .policy-section-img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .policy-text{
                    height: 100%;
                    padding:70px 0;
                    background: #eed4c6;
                    width:~"calc(100% - 475px)";
                    .policy-intro{
                        padding: 0 28px;
                        text-align: center;
                        font-family: "Montserrat";
                        font-size: 20px;
                        color: #000000;
                    }
                    .seperator{
                        width:100px;
                        margin: 38px auto;
                        display: block;
                        border-top:solid 0.5px #000000;
                    }
                    .all-policies{
                        padding: 0 64px 0 85px;
                        .policy-bloc{
                            &::before{
                                content: "";
                                margin-left:-41px;
                                margin-bottom: -36px;
                                display: inline-block;
                                width:25px;
                                height:10px;
                                border-left: solid 1px #000000;
                                border-bottom: solid 1px #000000;
                                .transform(rotate(-45deg));
                            }
                            // margin-bottom: 45px;
                            &::nth-last-child{
                                margin-bottom: 0;
                            }
                            .bloc-title{
                                font-family: "Montserrat";
                                font-size: 20px;
                                font-weight: 600;
                                color: #000000;
                            }
                            a.bloc-text{
                                text-decoration: underline;
                            }
                            .bloc-text{
                                line-height: 22px;
                                font-family: "Montserrat";
                                font-size: 18px;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }
    }

    .become-supplier{
        margin-top: 120px;
        .title-desc{
            margin-bottom: 9px !important;
        }
        .all-steps{
            margin-top: 47px;
            padding: 0 40px;
        }
        .supplier-step{
            width: ~"calc((100% - 120px)/3)";
            max-width:308px;
            position:relative;
            &:nth-of-type(n + 2){
                &::before{
                    content:"";
                    height:1px;
                    background:#c79b8d;

                    // width: 166px;
                    position:absolute;
                    // top
                    left:20px;
                    top:26px;
                    min-width:100px;
                    max-width:150px;
                    width:calc(~"(((100vw - 80px) - 924px) / 2) + 40px");
                    .transform(~"translateX(-100%)");
                }
            }
            .step-number{
                text-align: center;
                font-family: "Montserrat";
                font-size: 45px;
                font-weight: 500;
                color: #c79b8d;
            }
            .step-title{
                font-family: "Montserrat";
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: #000000;
                text-transform: uppercase;
            }
            .step-text{
                font-family: "Montserrat";
                font-size: 15px;
                line-height: 1.2;
                text-align: center;
            }
        }
        .steps-slider{
            width:100%;
            margin-top: 100px;
            height:auto;
            background: #eed4c6;
            .centered-container{
                // margin-right: 0px !important;
                max-width: 1280px;
                align-items: center;
            }
            .functionalities-swiper{
                position: relative;
                width: fit-content;
                margin: 50px 0 60px 0;
            }
            .dashboard-image{
                width: ~"calc(100% - 540px)";
            }
            .slider-title{
                text-align: center;
                padding-top:51px;
                text-transform: uppercase;
                font-family: "Montserrat";
                font-size: 34px;
                font-weight: 600;
            }
            .arrow-prev{
                cursor: pointer;
                position: absolute !important;
                top: -57px;
                .arrow;
                .transform(rotate(135deg));
                left:175px;
                margin: 42px 0;
            }
            .arrow-next{
                cursor: pointer;
                position: absolute !important;
                .arrow;
                .transform(rotate(-45deg));
                left: 175px;
            }
            .functionalities{
                padding: 0 40px;
                .swiper-container{
                    min-height: 500px;
                    height: 500px;
                    // height: -webkit-fill-available;
                }
                .swiper-slide{
                    width: 370px !important;
                    // height: 140px !important;
                    &.swiper-slide-prev{
                        height: fit-content !important;
                    }
                    &.swiper-slide-next{
                        height: fit-content !important;
                    }
                    &.swiper-slide-active{
                        height: fit-content !important;
                    }
                    &:not(.swiper-slide-active){
                    .transform(scale(0.8)) !important;
                    color: #4a4a4a;
                    }
                    .details-title{
                        font-family: "Montserrat";
                        font-size: 19px;
                        font-weight: 600;
                    }
                    .details-text{
                        font-family: "Montserrat";
                        font-size: 17px;
                    }
                }
            }
        }
    }

    .reviews{
        margin-top:120px;
        padding: 0 10px;
        .impression-swiper{
            overflow: hidden;
        }
        .title-desc{
            margin-bottom: 9px;
        }
        .reviews-desc{
            font-family: "Montserrat";
            font-size: 22px;
            text-align: center;
            margin-bottom: 60px;
        }
        .reviews-swiper{
            position: relative;
            padding: 0 40px;
        }
        .impression-arrow-prev{
            cursor: pointer;
            .arrow;
            left:4px;
            z-index: 3;
            position: absolute;
            display: inline-block;
            .transform(rotate(45deg));
            opacity: 0.6;
            top: 44%;
        }
        .impression-arrow-next{
            cursor: pointer;
            .arrow;
            position: absolute;
            right:1px;
            display: inline-block;
            .transform(rotate(225deg));
            opacity: 0.6;
            top: 44%;
            z-index: 3;
        }
        .swiper-container{
            padding: 10px 0;
            overflow: unset;
            .swiper-slide{
                &:not(.swiper-slide-active){
                    background-color: rgba(216, 216, 216, 0.31);
                    .transform(scale(0.78));
                    .quotation-mark{
                        background-color: rgba(216, 216, 216, 0.31);
                    }
                }
            }
            .swiper-button-prev, .swiper-button-next{
                .transform(scale(0.5));
                background-image: none;
            }
        }
        .swiper-slide{
            width:380px !important;
            border-radius: 6px;
            box-shadow: 1px 0 16px -1px rgba(0, 0, 0, 0.17);
            .impression-top{
                height:251px ;
                background-color: #f3f3f3;
            }
            .quotation-mark{
                background-color: white;
                width:62px;
                height: 62px;
                margin:auto;
                position: relative;
                top: -34px;
                border-radius: 30px;
                position: relative;
            }
            .quotation{
                .sprite;
                .transform(scale(0.25));
                width:136px;
                height: 100px;
                display: block;
                background-position: -284px -228px;
                position: absolute;
                top: -19px;
                left: -35px;
            }
            .impression-text{
                height:auto ;
                padding:25px 17px 17px 17px;
                
                .customer-title{
                    font-family: "Montserrat";
                    font-size: 15px;
                    text-align: center;
                    color: #4a4a4a;
                    line-height: 0;
                }
                .customer-name{
                    text-align: center;
                    font-family: "Montserrat";
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    margin-top: 0 !important;
                }
                .customer-feedback{
                    text-align: center;
                    line-height: 22px;
                    font-family: "Montserrat";
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }

    .media-impression{
        position: relative;
        margin-top: 120px;
        .reviews-swiper{
            position: relative;
            padding: 0 40px;
            .swiper-slide{
                width: 200px !important;
            }
            .media-card {
                margin-bottom: 0px;
                display: block;
                text-align: center;
                background-color: white;
                height: 70px;
                position: relative;
                transition: width 0.2s ease;
                .no-select;
                text-decoration:none !important;
                border-radius: 6px;
                filter: grayscale(100%);
                .card-title {
                    left: -30px;
                    width:100%;
                    height:100%;
                    .flex-container;
                    align-items: center;
                    padding:0 10px;
                    justify-content: space-around;
                    margin: 0;
                }
                .arabian-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 231px;
                    height:100px;
                    background-position: -280px -59px;
                }
                .elle-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 220px;
                    height:100px;
                    background-position: -280px -142px;
                }
                .entrepreneur-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 264px;
                    height:74px;
                    background-position: -16px -88px;
                }
                .vogue-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 266px;
                    height:82px;
                    background-position: -17px -158px;
                }
                .national-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 266px;
                    height:82px;
                    background-position: -17px -235px;
                }
                .gulf-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 220px;
                    height:82px;
                    background-position: -280px 34px;
                }
                .forbes-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 260px;
                    height:88px;
                    background-position: -11px -384px;
                }
                .gulfNews-logo{
                    position: absolute;
                    .sprite;
                    display: block;
                    .transform(scale(0.5));
                    width: 281px;
                    height:72px;
                    background-position: -11px -307px;
                }
            }
        }
        .mediaImpression-arrow-prev{
            cursor: pointer;
            .arrow;
            margin-top:0px;
            left:4px;
            z-index: 3;
            position: absolute;
            display: inline-block;
            .transform(rotate(45deg));
            opacity: 0.6;
            top: 50%;

        }
        .mediaImpression-arrow-next{
            cursor: pointer;
            .arrow;
            position: absolute;
            margin-top: 0px;
            right:1px;
            display: inline-block;
            .transform(rotate(225deg));
            opacity: 0.6;
            top: 50%;
            z-index: 3;
        }
    }

    .get-started{
        margin-top:100px;
        height: 698px;
        position: relative;
        .image-bottom{
            background:url(https://cdn.designer-24.com/angularapp/prodAssets/portalImages/images/Hero_Landscape_Trans_closet_college_campuses_STOCKSY.png) no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
        }
        .add-on-image{
            padding: 0 40px;
            height: 100%;
            display: flex;
            align-items: center;
            .start-text{
                position: absolute;
            }
            .image-title{
                font-family: "Montserrat";
                font-size: 61px;
                font-weight: bold;
                color: #ffffff;
                text-transform: initial;
            }
            .start-button{
                color:black;
                border-radius: 0;
                background-color: #ffffff;
                height: 78px;
                width: 308px;
                margin-left: 7px;
                font-family: "Montserrat";
                font-size: 25px;
                font-weight: bold;
                text-transform: initial;
                line-height: 70px;
            }  
        }
    }

    .subscribe{
        margin-top: 120px;
        margin-bottom: 90px;
        .sign-up-content{
            max-width: 500px;
            margin: auto;
            .signUp-form{
                width: 100%;
                position: relative;
                input{
                    border:0;
                    text-align: center;
                    width: 100%;
                    border-bottom:solid 0.7px #000000;
                    &::placeholder{
                        font-family: "Montserrat";
                        font-size: 17px;
                        font-weight: 600;
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}