.submissionStepContainer {
    height: 760px;
    max-width: 956px;
    overflow: scroll;
    border-radius: 8px;
    box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.2);
    background-color: #ffffff;
    padding:60px;
    border: 1px solid black; /* temporary shit */
    margin: 0 auto;
    .example-container{
        height: 200px;
        overflow: auto;
        border: 1px solid #ccc;
    }
    .subStep{
        .socialLink{
            margin-left: 30px;
        }
        .instagram{
            display: flex;
            font-family: 'Montserrat';
            font-size: 13.33px;
        }
        .inputError{
            color: red;
            font-size: 11.11px;
        }
        .mat-checkbox{
            width: 100%;
            margin-top: 20px;
            font-size: 16px;
            .mat-checkbox-label{
                white-space: initial;
                text-align: left;
            }
        }
        .formError{
            border-color: red !important;
        }
        .itemImages{
            // display: -webkit-box;
            // margin-top:65px;
        }
        .uploaderContainer{
            height: auto;
            margin: 15px auto auto 30px;
        }
        .input-wrapper{
            height: 40px;
        }
        .subStep-title{
            font-family: "Montserrat";
            font-size: 23.04px;
            color: #25262a;
            text-align: left;
        }
        .clearance-values{
            .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
                margin-top: 0px; 
            }
            .ng-select .ng-arrow-wrapper {
                margin-top: 5px;
            }
            .ng-select .ng-select-focused:not(.ng-select-opened)>.ng-select-container{
                border: solid 1px rgba(155, 155, 155, 0.39) !important;
            }
            .ng-select-container{
                width: 255px;
                // border: initial;
                .ng-value-container .ng-value{
                    color: #25262a;
                }
            }
            .ng-dropdown-panel{
                width: auto;
            }
        }
        .step-hasDropdown{
            .ng-select-container.ng-has-value{
                border:0;
                margin-top:5px;
            }
            .ng-value.ng-star-inserted{
                color:#22538b !important;
            }
            .ng-select .ng-arrow-wrapper .ng-arrow {
                border-color: #22538b transparent transparent;
            }
        }
        input::placeholder{
            font-size: 13px;
        }
        textarea::placeholder{
            font-size: 13px;
        }
        .social-media-label{
            text-align: initial;
        }
        .hasButtonClass{
            .supplier-button{
                margin-right: 26px;
                line-height: 0px;
                width: fit-content;
                border-radius: 5px;
                border: solid 1px rgba(155, 155, 155, 0.39);
                background: white;
                padding: 10px 17px;
                font-family: "Montserrat";
                font-size: 13px;
                height: 40px;
            }
            .color-button-wrapper{
                border-radius: 5px;
                margin-right: 10px;
                margin-bottom: 20px;
                display: flex;
                .supplier-button{
                    // border:0 !important;
                    margin-right: 0px !important;
                    margin-left: 0px;
                    width: 110px;
                    border-radius: 0px;
                    text-transform: capitalize;
                    border: solid 1px rgba(155, 155, 155, 0.39);
                }
                @media(max-width: 768px) {
                    .supplier-button {
                        font-size: 10px;
                        width: 85px;
                    }
                }
                .color-background{
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    width:40px;
                    height: 40px;
                    border: solid 1px rgba(155, 155, 155, 0.39);
                }
            }
        }
        .button-margin{
            margin-bottom: 20px;
        }
        .colorBlack{
            background-color:black !important;
            color: white !important;
        }
        .step-hasPopover{
            .fa.fa-info-circle{
                color:#9b9b9b;
                font-size: 19px;
            }
            .info-popover{
                padding: 9px 10px 0 6px;
                border:0;
                background-color: white;
                border-color: white;
                box-shadow: unset;
                &:hover,&:focus,&:active{
                    background-color: white !important;
                    border-color: white !important;
                    box-shadow: unset !important;
                }
            }
            .popover-body{
                font-size:10px;
                width:200px;
            }
            .popover{
                .arrow{
                    border:0px;
                }
            }
            .popover-header{
                text-align: center;
                color: white;
                border: 35px;
                background-color: grey;
                margin: 5px 133px 5px 12px;
                border-radius: 51px;
                padding: 5px;
                font-size: 10px;
            }
        }
        .submit-button{
            .supplier-button{
                width:200px;
                margin:auto;
            }
        }
    }  
}


