/*
** COLORS
*/
@nude_color:#E4BDB1;
@grey_highlight:rgba(225, 225, 225, 0.3);
@nudelight_color:#F7E4DD;
@nudedark_color:#E4BDB1;
//texts
@text_color1:#000;
@text_color2:#999;
@placeholder_color:#999;
 
//borders & lines
@grey_border:#ccc;
@seperator_color:#ccc;
 
//tabs
@tabs_nude:#E4BDB1;
@tabs_grey:#ccc;
 
//other colors
@progress_bar_color:#E4BDB1;
 
//social colors
@facebook_color:#3b5998;
@google_blue_color:#4885ed;
@google_red_color:#d34836;
@twitter_color:#326ada;
@whatsapp_green_color:#25D366;
@whatsapp_grey_color:#455a64;
 
//loaders & spinners
@d24_spinner:@nude_color;
 
//navigation button
@navigation_button:#E4BDB1;
 
@icon_background:#dfc7af;


.transition (@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    -ms-transition: @transition;
    -o-transition: @transition;
}
.transform(@string){
    -webkit-transform: @string;
    -moz-transform:      @string;
    -ms-transform:       @string;
    -o-transform:        @string;
}

.myriad{font-family: 'MyriadPro', sans-serif;font-weight: normal;}
.myriad-semibold{font-family: 'MyriadProSemiBold', sans-serif;font-weight: normal;}
.myriad-light {font-family: 'MyriadProLight', sans-serif;font-weight: normal;}

@import 'assets/less/defaults.less';

@import 'assets/less/sign-in-up.less';

.header-padding {
    padding-top: 80px;
}
.green {
    color: #90c18d !important;
}
.yellow {
    color: #e5c388 !important;
}
.red {
    color: #c18d8d !important;
}
.mat-mdc-tooltip-panel {
    background-color:grey !important;
    color: white;
    font-size: 12px;
}
.tooltipInfoBtn {
    width: 21px;
    height: 21px;
    background-color: #bc7878;
    border-radius: 50%;
    border:none;
    color:white;
}
.tooltipSpan {
    width: 46px;
    height: 22px;
    border-radius: 21px;
    background-color: #f0c8c8;
    display: block;
    color: #bc7878;
}
.tooltipText , .tooltipText *{
    font-family: "Montserrat";
    font-size: 10px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #bc7878;
 
}
.sb-outline .sb-wrapper {
    width: 100%;
}
.sb-outline > .sb-wrapper {
    width: 100%;
}
.sb-group > * {
    width: 100%;
}
.popover {
    border: none;
}
@media(max-width: 768px) {
    .mat-cell {
        font-size: 10px;
    }
    .mat-header-cell {
        font-size: 10px;
    }
}
.popover-arrow {
    display: none !important;
    &.arrow {
        display: none !important;
    }
}
.dashboardTitle {
    opacity: 0.5;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: #656589;
    margin-bottom: 30px;
    text-transform: uppercase;
    display:block;
}
.customEditView {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #25262a;
    transition: 0.2s all ease-in;
    background: white;
    border: solid 0.5px #979797;
}
@media(max-width: 1240px) {
    .customEditView, .btn-primary {
        font-size: 9px;
        padding: 5px;
    }
}
@media(max-width: 990px) {
    .customEditView{
        margin-bottom: 10px;
    }
    .m-t-20Mob {
        margin-top: 20px;
    }
}
.miniForm {
    padding: 15px;
}
.errMessage {
    color: red;
}
.successMessage {
    color: green;
}
.modal-content {
    padding: 15px !important;
}
.tick > * {
    transform: none !important;
}
.mat-cell {
    padding-top:5px;
    padding-bottom: 5px;
}
.no-outline {
    outline: none !important;
    box-shadow: none !important;
}
.sBtn2 {
    padding:5px;
    border-radius: 5px;
    border: solid 1px #fafcfe;
    transition: all .3s ease-in;
    cursor: pointer;
}
.sBtn {
    padding: 12px;
    background: transparent;
    border-radius: 5px;
    border: solid 1px #979797;
    transition: all 0.3s ease-in;
    cursor: pointer;
    &:hover {
        background:#979797;
        color: white;
    }
}
.noNegMargin {
    margin-left:0px !important;
    margin-right: 0px !important;
}
.ngx-charts {
    background: white;
    border-radius: 8px;
}
@media(max-width:990px) {
    .sBtn {
        &:hover {
            background: transparent;
            color: black;
        }
    }
}

.mat-row {
    cursor: pointer;
}
.m-t-300 {
    margin-top:300px;
}
.pcoded-micon-noLabel {
    margin: 0 auto;
    display: block;
    width: 30px;
}
.tooltipNew {
    width: 130px;
    height: 25px;
    border-radius: 3px;
    background-color: #bc7878;
    position: absolute;
    top: 42px;
    left: 40px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 5px;
}
@media(max-width: 768px) {
    .tooltipNew {
        width: 101px;
        height: 15px;
        position: absolute;
        top: 11px;
        left: 3px;
        padding: 1px;
        font-size: 9px;
    }
    .customEditView {
        font-size: 8px;
    } 
    .btn-primary {
        font-size: 9px;
        padding: 5px;
    }
}
.dashboardIconImg {
    max-width: 30px;
}
.tooltip-arrow .arrow {
    top: calc(50% - 22.5px);
}
.btn-primary {
    text-transform: capitalize !important;
}
.noborder {
    border: none !important;
}
::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
@media(max-width:768px) {
    .sidePaddingMob-5 {
        padding-left:5px;
        padding-right:5px;
    }
}

.m-l-300{
    margin-left: 300px !important;
}
.b-b {
    border-bottom: 1px solid #dde0ec;
}
.s-p-60 {
    padding-left: 60px;
    padding-right: 60px;
}
.typeIndicator {
    width: 100px;
    font-size: 15px;
    width: 82px;
    height: 22px;
    border-radius: 21px;
    display: block;
    text-align: center;
    &.pink {
        background-color: #f0c8c8;
    }
    &.yellow {
        background-color: #f0e3c8;
    }
}

.d24-spinner {
    margin: 30px auto;
    width: 40px;
    height: 40px;
    position: relative;
    list-style-type: none;
    li {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        &::before {
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            background-color: @d24_spinner;
            border-radius: 100%;
            -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
                animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
        }
        &:nth-of-type(2) {
            .transform(rotate(30deg));
            &::before {
                -webkit-animation-delay: -1.1s;
                        animation-delay: -1.1s;
            }
        }
        &:nth-of-type(3) {
            .transform(rotate(60deg));
            &::before {
                -webkit-animation-delay: -1s;
                        animation-delay: -1s;
            }
        }
        &:nth-of-type(4) {
            .transform(rotate(90deg));
            &::before {
                -webkit-animation-delay: -0.9s;
                        animation-delay: -0.9s;
            }
        }
        &:nth-of-type(5) {
            .transform(rotate(120deg));
            &::before {
                -webkit-animation-delay: -0.8s;
                        animation-delay: -0.8s;
            }
        }
        &:nth-of-type(6) {
            .transform(rotate(150deg));
            &::before {
                -webkit-animation-delay: -0.7s;
                        animation-delay: -0.7s;
            }
        }
        &:nth-of-type(7) {
            .transform(rotate(180deg));
            &::before {
                -webkit-animation-delay: -0.6s;
                        animation-delay: -0.6s;
            }
        }
        &:nth-of-type(8) {
            .transform(rotate(210deg));
            &:before {
                -webkit-animation-delay: -0.5s;
                        animation-delay: -0.5s;
            }
        }
        &:nth-of-type(9) {
            .transform(rotate(240deg));
            &::before {
                -webkit-animation-delay: -0.4s;
                        animation-delay: -0.4s;
            }
        }
        &:nth-of-type(10) {
            .transform(rotate(270deg));
            &::before {
                -webkit-animation-delay: -0.3s;
                        animation-delay: -0.3s;
            }
        }
        &:nth-of-type(11) {
            .transform(rotate(300deg));
            &::before {
                -webkit-animation-delay: -0.2s;
                        animation-delay: -0.2s;
            }
        }
        &:nth-of-type(12) {
            .transform(rotate(330deg));
            &::before {
                -webkit-animation-delay: -0.1s;
                        animation-delay: -0.1s;
            }
        }
    }
    &.button-spinner{
        margin:3px auto 2px;
    }
    &.inline-input-spinner{
        position: absolute;
        font-size: 1.4em;
        right: 0;
        bottom: 3px;
        width: 30px;
        height: 25px;
        margin:3px 0;
        li{
            &::before{
                width:13%;
            }
        }
    }
}

       .sign-up-form {
        position: relative;
        margin: auto;
        margin-top: 50px;
        margin-bottom: 130px;
        font-family: 'MyriadPro', sans-serif;
        font-weight: normal;
        text-align: center;
        width: ~"calc(100% - 20px)";
        max-width: 500px;
        padding-left: 15px;
        padding-right: 15px;
        .error-message{
            .myriad;
            font-size: 13px;
            text-align: left;
            left:10px;
            top:105px;
            color: @nudedark_color;
            position: absolute;
            text-transform: uppercase;
            letter-spacing: 0.5px;
        }
        .newsletter-success{
            font-size:1.3em;
            color:@text_color2;
            letter-spacing: 0.5px;
            .myriad;
        }
    }
    @media (max-width: 990px) {
        .sign-up-form {
            margin-bottom: 50px;
        }
    }

    input.sign-up-input {
        border: 0;
        outline: 0;
        display: block;
        width: 100%;
        margin: auto;
        padding-right: 30px;
        background: none;
        border-bottom: 1px solid @text_color2;
        border-radius: 0 !important;
        height:42px;
        &::placeholder {
            color: @text_color2;
            opacity: 1;
            font-style: normal;
            .myriad-semibold;
            font-weight: 400;
            font-size:15px;
            letter-spacing: 1px;
        }
         
        &:-ms-input-placeholder {
        color: @text_color2;
        opacity: 1;
        font-style: normal;
        .myriad-semibold;
        font-weight: 400;
        font-size:15px;
        letter-spacing: 1px;
        }
         
        &::-ms-input-placeholder {
        color: @text_color2;
        opacity: 1;
        font-style: normal;
        .myriad-semibold;
        font-weight: 400;
        font-size:15px;
        letter-spacing: 1px;
        }
        }
         
        .sign-up-go {
        cursor: pointer;
        background: none;
        border: 0;
        outline: none;
        position: absolute;
        font-size: 1.4em;
        right: 8px;
        bottom: 8px;
        width: 30px;
        height: 25px;
        overflow: hidden;
            &::before {
                content: '';
                width:16px;
                height:16px;
                display: block;
                border:1px solid @text_color2;
                border-left: none;
                border-bottom: none;
                .transform(rotate(45deg));
            }
        }
        .sign-up-go2 {
            cursor: pointer;
            background: none;
            border: 0;
            outline: none;
            position: absolute;
            font-size: 1.4em;
            left: 40px;
            top: 13px;
            width: 30px;
            height: 25px;
            overflow: hidden;
            &::before {
                content: '';
                transition: 0.3s all ease-in;
                width:7px;
                height:7px;
                display: block;
                border:1px solid @text_color2;
                border-left: none;
                border-bottom: none;
                .transform(rotate(135deg));
            }
        }

@import 'assets/less/defaults.less';
@import 'assets/less/component.less';
@import 'assets/less/header.less';
@import 'assets/less/home.less';
@import 'assets/less/fonts.less';
@import 'assets/less/login.less';
@import 'assets/less/additional.less';
@import 'assets/less/step-form.less';
@import 'assets/less/submitItem.less';


@import 'assets/less/targeting.less';

