@media(max-width: 1125px){
    .homePage{
        .become-supplier{
            .steps-slider{
                .dashboard-image{
                    width: ~"calc(100% - 450px)";
                }
            }
        }
    }
}
.sign-in-ad{
    .sign-in-message{
        position:relative;
        &::after{
            content:"";
            left: -12px;
        }
    }
}
@media(max-width: 1024px){
    .homePage{
        .become-supplier{
            .steps-slider{
                .dashboard-image{
                    width: ~"calc(100% - 450px)";
                }
                .slider-title{
                    font-size: 30px;
                }
            }
        }
    }

    .login-container{
        .sign-in-ad{
            .sign-in-message{
                &::after{
                    content:"";
                    height: 137px;
                    width: 185px;
                    top: 49px;
                    left:-30px;
                   
                }
                &::before{
                    content:"";
                    height: 125px;
                    // left: 166px;
                    width: 179px;
                }
                .message{
                    font-size: 42px;
                }
            }
        }
        .form-link{
            font-size: 15px;
        }
        .logsign-input {
            height: 45px;
            &.fb {
                &:before {
                    content: "\f09a";
                    font-size: 22px;
                    padding-right: 8px;
                }
            }
            &.google {
                &:before {
                    content: "";
                   padding-right: 8px;
                }
            }
            p {
                font-size: 13px;
            }
            .input {
                font-size: 15px;
            }
        }
        .login-form{
            width:  ~"calc((100% / 2) - 70px)";
            .input-wrapper{
                height: 45px;
                font-size: 13px;
            }
            .sign-in{
                padding: 30px 40px 0 40px;
               
                .supplier-button{
                    height: 45px;
                }
                .login-step2-title{
                    font-size: 15px;
                }
                .forgot-pass{
                    font-size: 13px;
                }
                .login-title{
                    font-size: 22px;
                }
                
            }
            .register-step2{
                .sign-in;
                .more-validation{
                    .break-line{
                        height: 1px;
                        background: #999999;
                        margin: 20px 0;
                    }
                }
                .register-step2-title{
                    margin-bottom: 30px;
                }
                .phone-number{
                    .mobile-number{
                        padding-left:10px;
                    }
                    &.input-wrapper{
                        padding-left: 0px !important;
                    }
                }
                
                .phone-number, .currency, .organisation{
                    height: 50px;
                    &.add-arrow{
                        color: #4a4a4a;
                    }
                    select{
                        border: 0;
                        width: 100%;
                        height: 100%;
                        font-family: "Montserrat";
                        font-size: 15px;
                        font-weight: 600;
                        color: #4a4a4a;
                        &.country-code{
                            background-color: rgba(225, 225, 225, 0.33);
                            width:90px;
                        }
                        &.currency-code, &.organisation-type,&.country-code{
                            -webkit-appearance: none;
                        }
                    }
                }
            }
            .sign-up{
                padding: 0 40px;
                align-items: center;
                .sign-up-message{
                    font-size: 13px;
                }
                .logsign-input{
                    height: 45px;
                    .register{
                        padding: 0 10px;
                        font-size: 13px
                    }
                }  
            }
        }
    }
}
@media(max-width: 990px){
    .after-submit-buttons{
        .supplier-button{
            width: ~"calc((100% - 80px) / 2)"  
        }
    }   
    .after-submit-title{
        font-size: 32px;
        &::before{
            content:'';
            position: absolute;
            background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x%20(1).png") 0 0 no-repeat;
            .transform(scale(0.4));
            width:415px;
            height: 417px;
            left:-200px;
            top:-240px;
        }
        &::after{
            content: '';
            position: absolute;
            background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x.png") 0 0 no-repeat;
            .transform(scale(0.4));
            width:415px;
            height: 417px;
            top:-240px;
            right:-200px;
        }
    }
    .after-submit-message{
        font-size: 16px;
    }
   
    .submissionStepContainer {
        .subStep{
            .ng-select-container
            .input-wrapper{
                height: 35px;
            }
            .subStep-title{
                font-size: 20px;
            }
            
            input::placeholder{
                font-size: 12px;
            }
            textarea::placeholder{
                font-size: 12px;
            }
            .hasButtonClass{
                .supplier-button{
                    font-size: 12px;
                    height: 35px;
                    // margin: 0px auto;
                    margin-right: 5px;
                }
                .color-button-wrapper{
                    .color-background{
                        width:37px;
                        height: 35px;
                    }
                }
            }
            .submit-button{
                .supplier-button{
                    width:200px;
                    margin:auto;
                }
            }
        }   
    }    

    .input-wrapper{
        height: 50px;
    }
    .homePage{
        .common-img-bg {
            // height: 630px;
            .inner-box{
                .supplierHome-form{
                    // margin-top: calc((100% /2) /2);
                    margin-right: 60px;
                    height: auto;
                    max-width: 390px;
                    padding: 30px 20px;
                    .form-title{
                        font-size:19px;
                    }
                    .rentals-formula{
                        // padding: 10px;
                        .select-option{
                            font-size:15px;
                        }
                    }
                    .revenue-range{
                        font-size: 26px;
                    }
                    .revenue-text{
                        font-size: 15px;
                    }
                    .form-button{
                        font-size: 16px;
                    }
                }
            }
        }
        .supplier-intro{
            margin-top:100px;
            .supplier-desc{
                padding: 0 30px;
                .text-desc{
                    font-size: 17px;
                }
            }
            .supplier-advantage{
                padding: 0 30px;
                margin-top:70px;
                .advantage{
                    width: ~"calc((100% - 90px)/3)";
                    .advantage-title{
                        font-size: 16px;
                    }
                    .advantage-text{
                        font-size: 13px;
                    }
                }
            }
            
        }
        .policies{
            .policy-desc{
                .image-text{
                    .policy-image{
                        width:350px;
                    }
                    .policy-text{
                        padding:50px 0 20px 0;
                        width:~"calc(100% - 350px)";
                        .policy-intro{
                            padding: 0 20px;
                            font-size: 16px;
                        }
                        .seperator{
                            width:80px;
                            margin: 30px auto;
                        }
                        .all-policies{
                            padding: 0 44px 0 65px;
                            .policy-bloc{
                                margin-bottom: 20px;
                                .bloc-title{
                                    font-size: 16px;
                                }
                                .bloc-text{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }  
}
@media(max-width: 900px){
    .submissionStepContainer{
        min-height: 820px;
    }
    .homePage{
        .title-desc{
            font-size:22px;
        }
        .get-started{
            height: 600px;
            .main-banner{
                height: 600px;
            }
            .add-on-image{
                .image-title{
                    font-size: 59px;
                }
                .start-button{
                    height: 75px;
                    line-height: 75px;
                    width: 300px;
                    font-size: 23px;
                }  
            }
        }
        .subscribe{
            margin-top: 100px;
            .sign-up-content{
                max-width: 450px;
                .signUp-form{
                    input{
                        &::placeholder{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 886px){
    .input-wrapper{
        height: 45px;
    }
    .homePage{
        .common-img-bg {
            .inner-box{
                .supplierHome-form{
                    // margin-top: calc((100% /2) /2);
                    margin-right: 50px;
                    max-width: 360px;
                    padding: 30px 20px;
                    .form-title{
                        font-size:18px;
                    }
                    .rentals-formula{
                        // padding: 8px;
                        .select-option{
                            font-size:15px;
                        }
                    }
                    .revenue-range{
                        font-size: 24px;
                    }
                    .revenue-text{
                        font-size: 13px;
                    }
                    .form-button{
                        font-size: 14px;
                    }
                }
            }
        }
        .become-supplier{
            .steps-slider{
                .dashboard-image{
                    width: ~"calc(100% - 435px)";
                }
                .slider-title{
                    font-size: 25px;
                }
                .arrow-prev{
                    margin: 22px 0;
                }
                .arrow-next{
                    // margin-top: 80px;
                }
                .functionalities{
                    .swiper-slide{
                        width: 350px !important;            
                    }
                }
            }
        }
    }
}
@media(max-width: 860px){
    .login-container{
        display: block;
        margin-top:138px;
        text-align: center;
        .sign-in-ad{
            .sign-in-message{
                margin: auto;
                width:200px;
                &::after{
                    content:"";
                    border-left:5px solid #000000;
                    border-bottom: 5px solid #000000;
                    height: 84px;
                    width: 145px;
                    position: absolute;
                    top: 27px;
                    // left: 255px;
                }
                &::before{
                    content: "";
                    height: 76px;
                    // left: 366px;
                    width: 125px;
                    // right: 240px;
                    border-right: 5px solid #000000;
                    border-top: 5px solid #000000;
                    position: absolute;
                    top: -13px;
                }
                .message{
                    font-size: 25px;                   
                }
            }
        }
        .logsign-input {
            border-radius: 5px;
            border: solid 1px rgba(155, 155, 155, 0.39);
            cursor: pointer;
        }
        .solid-button{
            text-transform: initial;
        }
        .form-link{
            color: #ffffff;
        }
        .logsign-input {
            position: relative;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.fb {
                color: white;
                background-color: #3b5998;
                &:before {
                    content: "\f09a";
                    font-family: "FontAwesome";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    text-align: center;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    font-size: 25px;
                }
            }
            &.google {
                color: black;
                &:before {
                    content: "";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    background-image: url("http://cdn.designer-24.com/angularapp/prodAssets/google.png");
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            p {
                position: absolute;
                top: 50%;
                left: ~"calc(50% - 70px)";
                transform: ~"translateY(-50%)";
                margin: 0px;
                font-size: 15px;
                font-weight: 500;
                font-family: "Montserrat";
            }
            .input {
                width: 100%;
                height: 100%;
                padding: 0 15px;
                border: 1px solid #999999;
                font-size: 17px;
                letter-spacing: 1px;
                &[name=password] {
                    padding-right: 50px;
                }
            }
        }
        .login-form{
            margin: 63px auto;
            min-width: 450px;
            .input-wrapper{
                height: 50px;
                padding-left:10px;
                color: #4a4a4a;
                font-family: "Montserrat";
                font-size: 15px;
                font-weight: 600;
            }
            .line-breaker-full{
                width: 100%;
                height: 2px;
                background: #999999;
                margin: 24px 0;
            }
            .line-breaker {
                margin: 10px 0;
                text-align: center;
                position: relative;
                font-size: 17px;
                &:after,&:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: ~"calc(50% - 25px)";
                    height: 2px;
                    background-color: #999999;
                    top: 50%;
                }
                &:before {
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
            .sign-in{
                padding: 43px 60px 0 60px;
                .supplier-button{
                    height: 50px;
                }
                .login-step2-title{
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 600;
                    text-align: center;
                    .facebook{
                        color: #2d2d94
                    }
                    .google{
                        color:#3d3d9e;
                    }
                }
                input{
                    &::placeholder{
                        font-family: "Montserrat";
                        font-size: 15px;
                        font-weight: 600;
                        color: #4a4a4a;
                    }  
                }
                .forgot-pass{
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 500;
                    color: #000000;
                    text-decoration: underline;
                    display: block;
                    margin-bottom: 20px;
                }
                .login-title{
                    text-align: center;
                    font-family: "Montserrat";
                    font-size: 24px;
                    font-weight: 600;
                    margin-bottom: 21px;
                }
                
            }
            .register-step2{
                .sign-in;
                .more-validation{
                    .break-line{
                        height: 1px;
                        background: #999999;
                        margin: 20px 0;
                    }
                }
                .register-step2-title{
                    font-family: "Montserrat";
                    font-size: 24px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 39px;
                }
                .phone-number{
                    .mobile-number{
                        // padding-left:10px;
                    }
                    &.input-wrapper{
                        padding-left: 0px !important;
                    }
                }
                
                .phone-number, .currency, .organisation{
                    height: 50px;
                    &.add-arrow{
                        color: #4a4a4a;
                    }
                    select{
                        border: 0;
                        width: 100%;
                        height: 100%;
                        font-family: "Montserrat";
                        font-size: 15px;
                        font-weight: 600;
                        color: #4a4a4a;
                        &.country-code{
                            background-color: rgba(225, 225, 225, 0.33);
                            width:90px;
                        }
                        &.currency-code, &.organisation-type,&.country-code{
                            -webkit-appearance: none;
                        }
                    }
                    input{
                        border:0;
                        border-left:solid 1px rgba(155, 155, 155, 0.39);
                    }
                }
            }
            .sign-up{
                width: 100%;
                display: inline-flex;
                justify-content: space-around;
                margin-bottom:21px;
                align-items: center;
                .sign-up-message{
                    margin-bottom: unset;
                    font-family: "Montserrat";
                    font-size: 12px;
                    font-weight: 500;
                    max-width: 195px;
                }
                .logsign-input{
                    width: auto;
                    max-width: 90px;
                    .register{
                        font-size: 13px;
                        position: initial;
                        text-align: center;
                        color: #000000;
                        line-height: 44px;
                        padding: 0 16px;
                    }
                }  
            }
        }
    }
}
@media(max-width: 800px){
    .homePage{
        .policies{
            .policy-desc{
                .image-text{
                    display: block;
                    .policy-image{
                        width:100%;
                        // height: 400px;
                    }
                    .policy-text{
                        padding:50px 0 20px 0;
                        width:100%;
                        .policy-intro{
                            padding: 0 20px;
                            font-size: 18px;
                        }
                        .seperator{
                            width:80px;
                            margin: 30px auto;
                        }
                        .all-policies{
                            padding: 0 64px 0 85px;
                            .policy-bloc{
                                &::before{
                                    content: "";
                                    margin-left:-41px;
                                    margin-bottom: -25px;
                                    display: inline-block;
                                    width:25px;
                                    height:10px;
                                    border-left: solid 1px #000000;
                                    border-bottom: solid 1px #000000;
                                    .transform(rotate(-45deg));
                                }
                                margin-bottom: 35px;
                                .bloc-title{
                                    font-size: 18px;
                                }
                                .bloc-text{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 768px){
    .homePage{
        .become-supplier{
            .steps-slider{
                .arrow-next, .arrow-prev{
                    display: none !important;
                }
            }
        }
        .media-impression{
            .mediaImpression-arrow-next, .mediaImpression-arrow-prev{
                display: none;
            }
        }
        .reviews{
            .impression-arrow-next, .impression-arrow-prev{
                display: none;
            }
        }
    }   
    .after-submit-title{
        font-size: 32px;
        &::before{
            content:'';
            position: absolute;
            background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x%20(1).png") 0 0 no-repeat;
            .transform(scale(0.4));
            width:415px;
            height: 417px;
            left:-250px;
            top:-240px;
        }
        &::after{
            content: '';
            position: absolute;
            background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x.png") 0 0 no-repeat;
            .transform(scale(0.4));
            width:415px;
            height: 417px;
            top:-240px;
            right:-250px;
        }
    }
    .step-title{
        font-size: 18px !important;
    }
    .step-subtitle{
        font-size: 15px !important;
    }
    .step-text{
        font-size: 13px !important
    }

    .slider-section {
        .slide {
            .slide-details {
                font-size: 13px;
            }
        }
    }
    .homePage{
        .title-desc{
            font-size:21px;
        }
        .subscribe{
            .sign-up-content{
                max-width: 300px;
                .signUp-form{
                    input{
                        &::placeholder{
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        .become-supplier{
            .steps-slider{
                .centered-container{
                    display: block;
                    margin: auto;
                }
                .dashboard-image{
                    width: 100%;
                    padding-bottom: 40px;
                }
                .slider-title{
                    font-size: 27px;
                    margin-bottom: 40px;
                }
                .arrow-prev{
                    top:unset;
                    left: 30px;
                    .transform(rotate(45deg));
                    z-index:3;
                }
                .arrow-next{
                    z-index:3;
                    right:30px;
                    top:24px;
                    left:auto;
                    .transform(rotate(226deg));
                    margin: 0px;
                }
                .functionalities-swiper{
                    margin-bottom: 0px;
                    padding: 0 20px;
                    position: relative;
                    width:100%;
                    height: 200px;
                }
                .functionalities{
                    height: 200px;
                    .swiper-container{
                        height: auto;
                        min-height: unset;
                    }
                    .swiper-slide{ 
                        min-height: 200px;
                        width: 100% !important;
                        text-align: center;                                
                        .details-title{
                            width: 80%;
                            margin: auto;
                            font-size: 19px;
                        }
                        .details-text{
                            width:80%;
                            margin: auto;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:768px) and (min-height:730px) {
    .e404 {
        position: absolute;
        left: 50%;
        top: ~"calc((100% - 306px)/2)";
        transform: translate(-50%, -50%);
    }
}
@media (min-width: 572px)and(max-width: 700px){
    .slider-section {
        .slide {
            .slide-details {
                font-size: 12px;
                .designer-name {
                    font-size: 14px;
                }
            }
        }
        margin-top: 60px;
    }
    .homePage{
        .supplier-intro{
            margin-top:100px;
            .supplier-desc{
                padding: 0 30px;
                .text-desc{
                    font-size: 15px;
                }
            }
            .supplier-advantage{
                padding: 0 30px;
                margin-top:70px;
                .advantage{
                    width: ~"calc((100% - 90px)/3)";
                    .advantage-title{
                        font-size: 16px;
                    }
                    .advantage-text{
                        font-size: 13px;
                    }
                }
            }   
        }
        .become-supplier{
            margin-top: 100px;
            .all-steps{
                padding: 0 30px;
            }
            .supplier-step{
                width: ~"calc((100% - 90px)/3)";
                &:nth-of-type(n + 2){
                    &::before{
                        content:"";
                        height:1px;
                        background:#c79b8d;
    
                        // width: 166px;
                        position:absolute;
                        // top
                        left:20px;
                        top:26px;
                        min-width:90px;
                        max-width:140px;
                        width:calc(~"(((100vw - 60px) - 894px) / 2) + 40px");
                        .transform(~"translateX(-100%)");
                    }
                }
                .step-number{
                    font-size: 43px;
                }
                .step-title{
                    font-size: 16px;
                }
                .step-text{
                    font-family: "Montserrat";
                    font-size: 13px;
                }
            }
        }
        .get-started{
            height: 500px;
            .main-banner{
                height: 500px;
            }
            .add-on-image{
                .image-title{
                    font-size: 55px;
                }
                .start-button{
                    height: 72px;
                    width: 275px;
                    font-size: 21px;
                }  
            }
        }
    }
}
@media(max-width: 600px){
    .after-submit-buttons{
        margin: 60px auto;
        display: block;
        .supplier-button{
            margin: auto  auto 30px auto;
            width: 50%;
        }
    }
    .after-submit-title{
        font-size: 25px;
    }
    .after-submit-message{
        font-size: 15px;
    }
    .homePage{
        .policies{
            .policy-desc{
                .title-desc{
                    margin-bottom:48px;
                }
                .image-text{
                    padding: 0px;
                    display: block;
                    .policy-image{
                        width:100%;
                        // height: 400px;
                    }
                    .policy-text{
                        padding:50px 0 20px 0;
                        width:100%;
                        .policy-intro{
                            padding: 0 20px;
                            font-size: 18px;
                        }
                        .seperator{
                            width:80px;
                            margin: 30px auto;
                        }
                        .all-policies{
                            padding: 0 64px 0 85px;
                            .policy-bloc{
                                &::before{
                                    content: "";
                                    margin-left:-41px;
                                    margin-bottom: -25px;
                                    display: inline-block;
                                    width:25px;
                                    height:10px;
                                    border-left: solid 1px #000000;
                                    border-bottom: solid 1px #000000;
                                    .transform(rotate(-45deg));
                                }
                                margin-bottom: 35px;
                                .bloc-title{
                                    font-size: 18px;
                                }
                                .bloc-text{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .submissionStepContainer{
        min-height: 880px;
        padding: 40px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media(max-width: 572px){
    .slider-list{
        margin-top:20px;
    }
    
    .homePage{
        .title-desc{
            margin-bottom: 25px;
        }
        .subscribe{
            margin:50px 0;
        }
        .supplier-intro{
            margin-top:50px;
            .supplier-desc{
                .text-desc{
                    font-size: 15px;
                    line-height: 24px;
                }
            }
            .supplier-advantage{
                display:block; 
                margin-top: 50px;             
                .advantage{
                    width:100%;
                    padding: 0 40px;
                    .advantage-title{
                        font-size: 16px;
                    }
                    .advantage-text{
                        font-size: 13px;
                    }
                }
            }   
        }
        .policies{
            margin-top:50px;
            .policy-desc{
                .title-desc{
                    margin-bottom:25px;
                }
                .image-text{
                    padding: 0px;
                    display: block;
                    .policy-text{
                        padding:50px 0 20px 0;
                        width:100%;
                        .policy-intro{
                            padding: 0 20px;
                            font-size: 16px;
                        }
                        .seperator{
                            width:80px;
                            margin: 30px auto;
                        }
                        .all-policies{
                            padding: 0 20px 0 59px;
                            .policy-bloc{
                                &::before{
                                    content: "";
                                    margin-left:-41px;
                                    margin-bottom: -25px;
                                    display: inline-block;
                                    width:25px;
                                    height:10px;
                                    border-left: solid 1px #000000;
                                    border-bottom: solid 1px #000000;
                                    .transform(rotate(-45deg));
                                }
                                margin-bottom: 35px;
                                .bloc-title{
                                    font-size: 17px;
                                }
                                .bloc-text{
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .become-supplier{
            .title-desc{
                margin-bottom: 0px;
            }
            margin-top: 50px;
            .all-steps{
                display: block;
                padding: 0 30px;
            }
            .supplier-step{
                width:100%;
                padding: 0 ;
                margin: auto;
                text-align: center;
                &:nth-of-type(n + 2){
                    &::before{
                        content:"";
                        height:0px;
                        background:#c79b8d;
                        position:absolute;
                        left:20px;
                        top:26px;
                        min-width:90px;
                        max-width:140px;
                        width:calc(~"(((100vw - 60px) - 894px) / 2) + 40px");
                        .transform(~"translateX(-100%)");
                    }
                }
                .step-number{
                    font-size: 45px;
                }
                .step-title{
                    font-size: 17px !important;
                }
                .step-text{
                    font-size: 14px;
                }
            }
            .steps-slider{
                margin-top: 50px;
                .slider-title{
                    padding-top:31px;
                    font-size: 24px;
                    margin-bottom: 60px;
                }
            }
        }
        .get-started{
            margin-top:50px;
            height: 400px;
            .main-banner{
                height: 400px;
            }
            .add-on-image{
                padding: 0 30px;
                .image-title{
                    text-align: center;
                    font-size: 42px;
                }
                .start-button{
                    height: 62px;
                    line-height: 62px;
                    width: 250px;
                    font-size: 19px;
                }  
            }
        }
        .reviews{
            margin-top:50px;
            padding: 0px;
            // .impression-swiper{
            //     overflow:unset;
            // }
            .title-desc{
                margin-bottom: 9px;
            }
            .reviews-desc{
                font-size: 17px;
                margin-bottom: 25px;
            }
            .arrow-prev{
                .arrow;
                left:-8px;
                display: inline-block;
                .transform(rotate(45deg));
                opacity: 0.6;
                top: 272px;
    
            }
            .arrow-next{
                .arrow;
                left: 1188px;
                display: inline-block;
                .transform(rotate(225deg));
                opacity: 0.6;
                top: -250px;
            }
            .customers-impression{
                padding: 0 40px;
                .impression{
                    flex-direction: column;
                    justify-content: space-around;
                    &.active{
                        margin-top:0;
                        width:~"calc(((100% - 100px)/3) + 50px)";
                        height: 552px;
                        margin-bottom: -70px; 
                        .impression-text{
                            height:~"calc(100% / 2)" ;
                            padding:43px 31px;
                            .customer-name{
                                margin-bottom: 16px;
                                font-size: 24px;
                            }
                            .customer-impression{
                                font-size: 18px;
                            }
                        }
                    }
                    width:~"calc(((100% - 100px)/3) - 50px)";
                    flex-direction: column;
                    height: auto;
                    margin-top: 34px;
                    border-radius: 6px;
                    box-shadow: 1px 0 16px -1px rgba(0, 0, 0, 0.17);
                    .impression-top{
                        height:~"calc(100% / 2)" ;
                        background-color: #f3f3f3;
                    }
                    .impression-text{
                        // &::before{
                        //     content:",,";
                        //     .transform(rotate(-180deg));
                        // }
                        height:~"calc(100% / 2)" ;
                        padding:17px;
                        .customer-name{
                            text-align: center;
                            font-family: "Montserrat";
                            font-size: 15px;
                            font-weight: 600;
                            margin-bottom: 10px;
                        }
                        .customer-feedback{
                            text-align: center;
                            line-height: 22px;
                            font-family: "Montserrat";
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 560px){
    .login-container{
        padding: 0px;
        .sign-in-ad{
            .sign-in-message{
                margin: auto;
                &::after{
                    content:"";
                    border-left:5px solid #000000;
                    border-bottom: 5px solid #000000;
                    height: 84px;
                    width: 145px;
                    position: absolute;
                    top: 27px;
                    // left: 255px;
                }
                &::before{
                    content: "";
                    height: 76px;
                    // left: 366px;
                    width: 125px;
                    // right: -19px;
                    border-right: 5px solid #000000;
                    border-top: 5px solid #000000;
                    position: absolute;
                    top: -13px;
                }
                .message{
                    font-size: 25px;                   
                }
            }
        }
       
        .logsign-input {
            position: relative;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.fb {
                color: white;
                background-color: #3b5998;
                &:before {
                    content: "\f09a";
                    font-family: "FontAwesome";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    text-align: center;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    font-size: 25px;
                }
            }
            &.google {
                color: black;
                &:before {
                    content: "";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    background-image: url("http://cdn.designer-24.com/angularapp/prodAssets/google.png");
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            p {
                position: absolute;
                top: 50%;
                left: ~"calc(50% - 70px)";
                transform: ~"translateY(-50%)";
                margin: 0px;
                font-size: 14px;
                font-weight: 500;
                font-family: "Montserrat";
            }
            .input {
                width: 100%;
                height: 100%;
                padding: 0 15px;
                border: 1px solid #999999;
                font-size: 14px;
                letter-spacing: 1px;
                &[name=password] {
                    padding-right: 50px;
                }
            }
        }
        .login-form{
            min-width: 325px;
            .input-wrapper{
                height: 45px;
                font-size: 13px;
            }
            
            .sign-in{
                padding: 34px 50px 0 50px;
                .supplier-button{
                    height: 45px;
                }
                .login-step2-title{
                    font-size: 13px;
                }
                input{
                    &::placeholder{
                        font-size: 13px;
                    }  
                }
                .forgot-pass{
                    font-size: 13px;
                }
                .login-title{
                    font-size: 20px;
                }
                
            }
            .register-step2{
                .sign-in;
               
                .register-step2-title{
                    font-size: 20px;
                }
               
                .phone-number, .currency, .organisation{
                    height: 45px;
                    select{
                        font-size: 13px;
                        
                    }
                }
            }
            .sign-up{
                .sign-up-message{
                    font-size: 11px;
                }
                .logsign-input{
                    .register{
                        font-size: 12px;
                    }
                }  
            }
        }
    }
}
@media(max-width: 500px){
    
    .header-container{
        .header-section{
            height:100%;
            padding: 0 30px;
        }
        .supplier-button{
            width:150px;
        }
        .solid-button{
            font-size:14px;
        }
        .header-login{
            padding-left:15px;
            font-size: 13px;
        }
        
    }
    .input-wrapper{
        height: 45px;
    }
    .homePage{
        .title-desc{
            font-size: 19px;
        }
        .common-img-bg {
            .inner-box{
                .supplierHome-form{
                    // margin-top: 40%;
                    margin-right: auto;
                }
            }
        }
    }

}

@media(max-width: 480px){
    .e404 {
        .message {
            font-size: 1.4em;
        }
    }
    .submissionStepContainer {
        padding: 40px;
        padding-left: 15px;
        padding-right: 15px;
        .subStep{
            .mat-checkbox{
                font-size: 13.33px !important;
            }
            .inputError{
                font-size: 9.26px !important;
            }
            .instagram{
                font-size: 11.11px !important;
            }
            .subStep-title{
                font-size: 16px !important;
            }
        }
    }
    .step-title{
        font-size: 23.04px !important;
    }
    .step-subtitle{
        font-size: 19.20px !important;
    }
    .homePage{
        .become-supplier{
            .steps-slider{
                .slider-title{
                    font-size: 20px;
                }
                .functionalities{
                    .swiper-slide{
                        .details-title{
                            font-size: 17px;
                        }
                        .details-text{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 432px){
    .login-container{
        .sign-in-ad{
            .sign-in-message{
                margin: auto;
                &::after{
                    content:"";
                    border-left:5px solid #000000;
                    border-bottom: 5px solid #000000;
                    height: 84px;
                    width: 145px;
                    position: absolute;
                    top: 27px;
                    // left: 255px;
                }
                &::before{
                    content: "";
                    height: 76px;
                    // left: 366px;
                    width: 125px;
                    // right: -19px;
                    border-right: 5px solid #000000;
                    border-top: 5px solid #000000;
                    position: absolute;
                    top: -13px;
                }
                .message{
                    font-size: 22px;                   
                }
            }
        }
       
        .logsign-input {
            position: relative;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            &.fb {
                color: white;
                background-color: #3b5998;
                &:before {
                    content: "\f09a";
                    font-family: "FontAwesome";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    text-align: center;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    font-size: 23px;
                }
            }
            &.google {
                color: black;
                &:before {
                    content: "";
                    pointer-events: none;
                    display: block;
                    width: 25px;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: ~"translateY(-50%)";
                    background-image: url("http://cdn.designer-24.com/angularapp/prodAssets/google.png");
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            p {
                position: absolute;
                top: 50%;
                left: ~"calc(50% - 70px)";
                transform: ~"translateY(-50%)";
                margin: 0px;
                font-size: 12px;
                font-weight: 500;
                font-family: "Montserrat";
            }
            .input {
                width: 100%;
                height: 100%;
                padding: 0 15px;
                border: 1px solid #999999;
                font-size: 14px;
                letter-spacing: 1px;
                &[name=password] {
                    padding-right: 50px;
                }
            }
        }
        .login-form{
            min-width: unset;
            width:100%;
            .input-wrapper{
                height: 45px;
                font-size: 11px;
            }
            
            .sign-in{
                padding: 34px 50px 0 50px;
                .supplier-button{
                    font-size: 12px;
                    height: 45px;
                }
                .login-step2-title{
                    font-size: 11px;
                }
                input{
                    &::placeholder{
                        font-size: 11px;
                    }  
                }
                .forgot-pass{
                    font-size: 11px;
                }
                .login-title{
                    font-size: 17px;
                }
                
            }
            .register-step2{
                .sign-in;
               
                .register-step2-title{
                    font-size: 17px;
                }
               
                .phone-number, .currency, .organisation{
                    height: 45px;
                    select{
                        font-size: 11px;
                        
                    }
                }
            }
            .sign-up{
                .sign-up-message{
                    font-size: 10px;
                }
                .logsign-input{
                    .register{
                        font-size: 11px;
                    }
                }  
            }
        }
    }
    .header-container{
        .header-section{
            height:100%;
            padding: 0 10px;
        }
        .supplier-button{
            width:fit-content;
            padding: 0 7px !important;
        }
        .solid-button{
            font-size:12px;
        }
        .header-login{
            font-size: 12px;
        }
        
    }
    .homePage{
        .become-supplier{
            .steps-slider{
                .functionalities-swiper{
                    height: 250px;
                }
            }
        }
    }
}

@media(max-width : 376px){
    .homePage{
        .title-desc{
            font-size: 19px;
        }
        .get-started{
            height: 400px;
            .add-on-image{
                .image-title{
                    text-align: center;
                    font-size: 42px;
                }
                .start-button{
                    line-height: 60px;
                    height: 60px;
                    width: 200px;
                    font-size: 19px;
                }  
            }
        }
    }
}
@media(max-width: 330px){
    .submissionStepContainer {
        padding: 30px;
        padding-left: 5px;
        padding-right: 5px;
    }
    .submissionStepContainer .subStep .hasButtonClass .color-button-wrapper {
        width:115px;
    }
}
@media(max-width: 320px){
    .homePage {
        .become-supplier{
            .steps-slider{
                .functionalities-swiper{
                    height: 270px;
                }
            }
        }
    }
}

// @media(max-width: 1240){
//     .impression{
//         &.active{
//             max-width: 400px !important
//         }
//         max-width: 350px !important;
//     }
// }
// @media (min-width: 767px)and(max-width: 1000px) {
//     .common-img-bg {
//         height: 620px;
//     }
// }
// @media(max-width: 990px){
//     .supplier-desc, .supplier-advantage{
//         padding: 0 10px !important;
//     }
//     .policy-image{
//         width:350px !important;
//     }
//     .policy-text{
//         padding-top: 60px !important;
//         width: ~"calc(100% - 350px)" !important;
//         .policy-intro{
//             font-size: 18px !important;
//         }
//         .seperator{
//             margin: 34px auto !important;
//         }
//         .policy-bloc{
//             margin-bottom: 30px !important;
//             .bloc-title{
//                 font-size: 18px !important;
//             }
//             .bloc-text{
//                 font-size:16px !important;
//             }
//         }
//     }
//     .slider-title{
//         font-size:30px !important;
//     }
//     .details{
//         &:nth-child(3){
//             margin-bottom: 0px !important;
//         }
//         width: 350px !important;
//         margin-bottom:45px !important;
//     }
//     .details-title{
//         font-size: 18px !important;
//     }
//     .details-text{
//         font-size: 16px !important;
//     }
//     .image-title{
//         font-size: 59px !important;
//     }
//     .start-button{
//         width: 300px !important;
//         height: 75px !important;
//         font-size: 23px !important;
//     }
// }

// @media(max-width: 850px){
//     .supplier-intro{
//         margin-top: 100px !important;
//     }
//     .supplier-advantage{
//         margin-top: 100px !important; 
//     }
//     .title-desc{
//         font-size: 23px !important;
//     }
//     .text-desc{
//         font-size: 17px !important;
//     }
//     .advantage-title{
//         font-size: 17px !important;
//     }
//     .advantage-text{
//         font-size: 14px !important;
//     }
//     .policy-intro{
//         font-size: 16px !important;
//     }
//     .seperator{
//         width: 80px !important;
//     }
//     .bloc-title{
//         font-size: 16px !important;
//     }
//     .bloc-text{
//         font-size: 14px !important;
//     }
//     .policy-bloc{
//         margin-bottom: 0 !important;
//     }
// }
// @media (max-width: 990px) {
//     .supplierHome-form{
//         max-width: 350px !important;
//         height: 300px;
//         margin-right:40px !important;
//     }
//     .logsign {
//         .logsign-ad {
//             h1 {
//                 font-size: 45px;
//             }
//         }
//     }
// }

// @media (max-width: 767px) {
//     .supplierHome-form{
//         height: 350px !important;
//         padding:30px 20px !important;
//         margin-right:auto !important;
//         margin-left: auto;
//         // margin-top: 60px !important;
//         .form-title{
//             font-size:19px !important;
//         }
//         .rentals-formula{
//             padding: 0 0 0 18px !important;
//         }
//         .input-wrapper{
//             height: 50px;
//         }
//         .revenue-range{
//             font-size: 19px !important;
//         }
//         .revenue-text{
//             font-size: 16px !important
//         }
//     }
//     .supplier-advantage{
//         margin-top: 80px !important;
//     }
//     .policies{
//         margin-top: 80px !important;
//     }
//     .supplier-intro{
//         margin-top:80px !important;
//         padding: 0 5px !important; 
//         .title-desc{
//             font-size: 22px !important;
//         }
//         .text-desc{
//             font-size: 16px !important;
//         }
//     }
//     .header-container{
//         .supplier-button{
//             width: 150px !important;
//         }
//         .solid-button{
//             font-size: 13px !important;
//         }
//     }
    
//     .header-login{
//         font-size: 13px !important;
//     }
//     .logsign {
//         margin-top: 25px !important;
//         margin-bottom: 25px !important;
//         .logsign-breadcrumb {
//             display: none;
//         }
//         .logsign-input.submit {
//             width: 100%;
//         }
        
//         .logsign-container {
//             margin: auto;
//             max-width: 500px;
//         }
//         .logsign-ad {
//             display: none;
//         }
//         .terms {
//             width: 100%;
//             font-size: 12px;
//         }
//     }
// }