.step-title{
    font-family: "Montserrat";
    font-size: 39.81px;
    font-weight: 600;
    color: #afb0c3;
    text-transform: uppercase;
    margin-bottom:0; 
}
.step-subtitle{
    font-family: "Montserrat";
    font-size: 23.04px;
    font-weight: 500;
    color: #afb0c3;
}
.message{
    margin:auto;
}
.arrow-next-next {
    .rightRs;
    .btn;
    .btn-primary;
    z-index: 999;
}
.arrow-prev-prev {
    .sBtn2;
    .leftRs;
    color: black;
    z-index: 999;

}
.after-submit-buttons{
    max-width: 674px;
    // width: 674px;
    margin: 90px auto ;
    .supplier-button{
        width: ~"calc((100% - 40px) / 2)"  
    }
}
.goBack-button{
    background: #fff;
    color: #000;
    text-transform: uppercase;
    border: 1px solid #000;
}
      
.after-submit-title{
    width:50%;
    position: relative;
    margin: auto auto 30px auto;
    text-align: center;
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 600;
    color: #afb0c3;
    &::before{
        content:'';
        position: absolute;
        background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x%20(1).png") 0 0 no-repeat;
        .transform(scale(0.4));
        width:415px;
        height: 417px;
        left:-200px;
        top:-240px;
    }
    &::after{
        content: '';
        position: absolute;
        background: url("http://cdn.designer-24.com/angularapp/prodAssets/noun-confetti-769740@2x.png") 0 0 no-repeat;
        .transform(scale(0.4));
        width:415px;
        height: 417px;
        top:-240px;
        right:-200px;
    }
}
.after-submit-message{
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #afb0c3;
}
.after-submit-section{
    padding-top:150px; 
  
}

.bannerStyle h1 {
    background-color:#fafcfe;
    min-height: 300px;
    text-align: center;
    line-height: 300px;
}

