.centered-container {
    display: block;
    margin: auto;
     max-width: 1200px;
}
.header-section{
    display: block;
    margin: auto;
}

.default-padding {
    padding: 0 10px
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
}

.flex-nowrap{
    flex-wrap: nowrap;
}
.space-between{
    justify-content: space-between;
}
.space-around{
    justify-content: space-around;
}
.center-vertical-transform{
    position:absolute;
    top:50%;
    .transform(~"translateY(-50%)");
}
.sprite{
    background: url(https://cdn.designer-24.com/angularapp/prodAssets/supplier-sprite-3.png) 0 0 no-repeat;
}
.center-transform{
    position:absolute;
    left:50%;
    top:50%;
    .transform(~"translateX(-50%) translateY(-50%)");
}
.no-select{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }