// @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

//Font awesome
.fontawesome{font-family: FontAwesome;}

//MontSerrat
.montserrat{font-family: 'Montserrat', sans-serif; font-weight: normal;}
.montserrat-light{font-family: 'Montserrat', sans-serif; font-weight: 300;}
.montserrat-medium{font-family: 'Montserrat', sans-serif; font-weight: 500;}
.montserrat-semibold{font-family: 'Montserrat', sans-serif; font-weight: 600;}
.montserrat-bold{font-family: 'Montserrat', sans-serif; font-weight: 700;}


