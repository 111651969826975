.sign-in-ad{
    margin-top: 70px;
    .sign-in-message{
        position:relative;
        &::after{
            content:"";
            border-left:5px solid #000000;
            border-bottom: 5px solid #000000;
            width: 272px;
            height: 187px;
            position: absolute;
            top: 62px;
            left: -39px;
        }
        &::before{
            content: "";
            right: -23px;
            border-right: 5px solid #000000;
            border-top: 5px solid #000000;
            width: 272px;
            height: 159px;
            position: absolute;
            top: -10px;
        }
        .message{
            font-family: "Montserrat";
            font-size: 60px;
            font-weight: 500;
           
        }
    }
}
.solid-button{
    background:#000;
    color:#fff;
    text-transform: uppercase;
    border:1px solid #000;
    transition: opacity 0.3s ease;
    &.disabled{
        opacity: 0.6;
        pointer-events: none;
    }
    &:disabled{
        opacity: 0.6;
        pointer-events: none;
    }
}
.supplier-button{
    text-align: center;
    height:45px;
    line-height: 45px;
    font-family: "Montserrat";
    width:100%;
    display:block;
    text-decoration: none !important;
    cursor: pointer;
    border-radius: 4px;
    font-size: 13px;
    .transition(~"color 0.3s, background 0.3s, border 0.3s");
}

.input-wrapper{
    width: 100%;
    height: 55px;
}
.example-loading-shade{
    .submit-link{
        color: #980000;
        text-decoration: underline;
    }
}
.add-arrow{
    position: relative;
    &::before{
        content:'\f107';
        pointer-events: none;
        position: absolute;
        z-index: 1;
        right:10px;
        .center-vertical-transform;
        font-family: "FontAwesome";
        font-size: 20px;
        color:rgba(151, 151, 151, 0.3);
    }
}
.sBtn2 {
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #fafcfe;
    transition: all .3s ease-in;
    cursor: pointer;
}
.rightRs {
    position: absolute;
    margin: auto;
    bottom: 85px;
    width: 100px;
    top: auto;
    height: 35px;
    right: 70px;
    cursor: pointer;
    font-size: 12px;
    opacity: 1;
}
.leftRs {
    opacity: 1;
    position: absolute;
    margin: auto;
    bottom: 85px;
    top: auto;
    width: 100px;
    height: 35px;
    background: #fafcfe;
    border: 1px solid black;
    font-size: 12px;
    left: 70px;
    cursor: pointer;
}
.btn-primary,
.sweet-alert button.confirm,
.wizard>.actions a {
    /* background-color: #dfc7af !important; buttonBG */
    background-color: black !important;
    border-color: black !important;
    color: white;
    cursor: pointer;
    transition: all ease-in 0.3s;
    text-transform: uppercase;
}
.btn {
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 19px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}
.arrow{
    border-left:solid 1px #000000;
    border-bottom: solid 1px #000000;
    width:14px;
    height: 14px;
    position: relative;
    margin-top:20px;
    display: block;
}
.input-wrapper{
    border-radius: 5px;
    padding-left: 15px; 
    border: solid 1px rgba(155, 155, 155, 0.39);
    margin-bottom: 10px;   
}
.e404 {
    background: antiquewhite;
    height: 100vh;
    width: 100%;
    text-align: center;
    position: relative;

    .centered-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: ~"translate(-50%, -50%)";
        width: ~"calc(100% - 20px)";
    }

    .title {
        .montserrat;
        font-weight: normal;
        font-size: 2.1em;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .message {
        .montserrat;
        font-weight: normal;
        font-size: 1.5em;
        margin-bottom: 25px;
        text-transform: uppercase;
    }

    .d24-button {
        max-width: 250px;
        margin: auto;
        margin-top: 95px;
    }
}
