.logsign {
    margin-top: 50px;
    margin-bottom: 20px;
    &.additional {
        margin-top: 35px;
        margin-bottom: 20px;
        max-width: 1000px;
        .logsign-input {
            &.checkbox, &.submit {
                margin-bottom: 10px !important;
            }
            &.submit {
                margin-top: 30px;
            }
        }
    }
    &.phone-verify {
        margin-top: 35px;
        margin-bottom: 20px;
        max-width: 750px;
        .logsign-container {
            max-width: 100% !important;
        }
        .logsign-input {
            margin: auto;
            width: 100% !important;
            max-width: 350px;
            .secondary-input {
                margin-top: 20px;
            }
        }
        .logsign-extra {
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 25px;
        }
        .mainAlert {
            display: block;
            width: 100%;
            max-width: 600px;
            margin: auto;
            margin-bottom: 20px;
        }
    }
    &.forget {
        margin-top: 60px;
        margin-bottom: 60px;
        max-width: 1000px;
        .logsign-container {
            max-width: 600px;
        }
        .logsign-description {
            text-align: left;
        }
    }
    .logsign-title {
        text-align: center;
        font-size: 22px;
        margin-bottom: 12px;
    }
    .logsign-description {
        text-align: center;
        font-size: 20px;
        margin-bottom: 35px;
    }
    .logsign-breadcrumb {
        font-size: 20px;
        margin-bottom: 20px;
        letter-spacing: 1.2px;
    }
    .logsign-container {
        width: 100%;
        max-width: 375px;
        .line-breaker {
            margin: 30px 0;
            text-align: center;
            position: relative;
            font-size: 17px;
            &:after,&:before {
                content: "";
                display: block;
                position: absolute;
                width: ~"calc(50% - 25px)";
                height: 1px;
                background-color: #999999;
                top: 50%;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
    }
    .logsign-social {
        .logsign-input {
            border: 1px solid #999999;
            cursor: pointer;
        }
    }
    .logsign-input {
        position: relative;
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
        &.fb {
            color: white;
            background-color: #3b5998;
            &:before {
                content: "\f09a";
                font-family: "FontAwesome";
                pointer-events: none;
                display: block;
                width: 25px;
                text-align: center;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: ~"translateY(-50%)";
                font-size: 25px;
            }
        }
        &.google {
            color: black;
            &:before {
                content: "";
                pointer-events: none;
                display: block;
                width: 25px;
                height: 25px;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: ~"translateY(-50%)";
                background-image: url("http://cdn.designer-24.com/angularapp/prodAssets/google.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        p {
            position: absolute;
            top: 50%;
            left: ~"calc(50% - 70px)";
            transform: ~"translateY(-50%)";
            margin: 0px;
            font-size: 1.214em;
            font-weight: 600;
            letter-spacing: 1px;
        }
        .input {
            width: 100%;
            height: 100%;
            padding: 0 15px;
            border: 1px solid #999999;
            font-size: 17px;
            letter-spacing: 1px;
            &[name=password] {
                padding-right: 50px;
            }
            &.ng-select {
                padding: 0px;
                .ng-arrow-wrapper {
                    margin-top: 0px;
                }
                .ng-select-container {
                    height: 100%;
                    border: none !important;
                    background: transparent;
                    .ng-value-container {
                        margin: 0;
                        padding: 0;
                        padding-left: 15px;
                        font-size: inherit;
                        .ng-value {
                            padding: 0px;
                            margin: 0px;
                            font-size: inherit;
                        }
                    }
                }
                .ng-value-label {
                    color: black;
                }
            }
            &.ng-dropdown-panel {
                padding: 0px;
                border: 1px solid #999999;
                width: ~"calc(100% + 2px)";
                margin-left: -1px;
                height: auto;
                .ng-option {
                    padding: 8px 15px;
                }
            }
            .minimal.ng2-tag-input {
                position: absolute;
                padding-left: 15px !important;
                background: transparent !important;
                left: 0;
                top: 50%;
                transform: ~"translateY(-50%)";
            }
            .ng2-tags-container {
                .flex-container !important;
                .flex-nowrap !important;
            }
            tag-input-form {
                width: initial !important;
            }
        }
        &.submit {
            width: 220px;
            margin-top: 40px;
            margin-bottom: 20px !important;
            .input {
                background: black;
                color: white;
                cursor: pointer;
                text-transform: uppercase;
                .myriad-semibold;
            }
        }
        &.phone {
            .flex-container;
            .flex-nowrap;
            .input:first-child {
                width: 90px;
                padding-left: 30px;
                border-right: none;
            }
            .input:last-child {
                width: ~"calc(100% - 90px)";
            }
            &:before {
                content: "+";
                position: absolute;
                font-size: 23px;
                color: #B4B4B4;
                height: 100%;
                width: 30px;
                padding-top: 6px;
                padding-left: 10px;
            }
        }
        &.code {
            .flex-container;
            justify-content: space-evenly;
            width: 100%;
            max-width: 300px;
            margin: auto;
            .input {
                width: 50px;
                padding-left: 20px;
            }
        }
    }
    .countdown {
        margin: auto;
        margin-top: 25px;
        width: 40px;
        height: 40px;
        text-align: center;
        background: black;
        color: white;
        line-height: 40px;
        font-size: 17px;
        border-radius: 50%;
    }

    .logsign-extra {
        .message {
            .myriad-light;
            font-size: 18px;
            a, button {
                .myriad;
                font-size: inherit;
                color: black;
                text-decoration: none !important;
                cursor: pointer;
                border: none;
                background: transparent;
                transition: color 0.2s ease;
            }
            button {
                margin-left: 30px;
                text-decoration: underline !important;
                &:disabled {
                    color: #B4B4B4;
                    cursor: default;
                }
            }
            &.code {
                .myriad;
            }
        }
    }
    .customCheckbox {
        border: 1px solid #999999 !important;
        transition: background 0.2s ease-in !important;
        &,&+label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &+label {
            left: 40px;
            font-size: 17px;
        }
        &:checked {
            background-color: white;
            &:after {
                content: "\F00C";
                font-family: "FontAwesome";
                display: block;
                width: 100%;
                height: 100%;
                font-size: 23px;
                line-height: 25px;
                text-align: center;
            }
        }
    }
    .terms {
        .myriad-light;
        width: 200%;
        max-width: 750px;
        font-size: 17px;
        letter-spacing: 0.5px;
        a {
            font-size: 17px;
            color: black;
            text-decoration: underline !important;
            &:hover {
                color: black;
            }
        }
    }
    .logsign-ad {
        h1 {
            font-size: 55px;
        }
    }
}

.passEye{
    z-index: 1;
    width: 50px;
    height: 100%;
    position: absolute;
    right: 0;
    opacity: 0.7;
    top: 0px;
    img {
        width: 25px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: ~"translate(-50%, -50%)"
    }
}