.login-container{
    padding: 0 50px;
    margin-top: 140px;
    .logsign-input {
        border-radius: 5px;
        border: solid 1px rgba(155, 155, 155, 0.39);
        cursor: pointer;
    }
    .solid-button{
        text-transform: initial;
    }
    .form-link{
        color: #ffffff;
    }
    .logsign-input {
        position: relative;
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
        &.fb {
            color: white;
            background-color: #3b5998;
            &:before {
                content: "\f09a";
                font-family: "FontAwesome";
                pointer-events: none;
                display: block;
                width: 25px;
                text-align: center;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: ~"translateY(-50%)";
                font-size: 25px;
            }
        }
        &.google {
            color: black;
            &:before {
                content: "";
                pointer-events: none;
                display: block;
                width: 25px;
                height: 25px;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: ~"translateY(-50%)";
                background-image: url("http://cdn.designer-24.com/angularapp/prodAssets/google.png");
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
        p {
            // padding-left: 15px;
            position: absolute;
            top: 50%;
            left: ~"calc(50% - 70px)";
            transform: ~"translateY(-50%)";
            margin: 0px;
            font-size: 15px;
            font-weight: 500;
            font-family: "Montserrat";
        }
        .input {
            width: 100%;
            height: 100%;
            padding: 0 15px;
            border: 1px solid #999999;
            font-size: 17px;
            letter-spacing: 1px;
            &[name=password] {
                padding-right: 50px;
            }
        }
    }
    .login-form{
        margin: auto;
        width:  ~"calc((100% / 2) - 110px)";
        border-radius: 5px;
        box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.3);
        background-color: #ffffff;
        .nosocial-container{
            padding: 43px 60px 0 60px;
            position: inherit;
            width:100%;
            top:58px;
            .nosocial-title{
                font-family: "Montserrat";
                font-size: 17px;
                font-weight: 600;
                text-align: center;
            }
        }
        .backTo-sign-in-up{
            position: relative;
            .nosocial-signUp{
                position: absolute;
                left: 10px;
                .montserrat;
                font-size: 12px;
            }
            .nosocial-back-login{
                position: absolute;
                right:10px;
                .montserrat;
                font-size: 12px;
            }
        }
        .input-wrapper{
            height: 50px;
            padding-left:10px;
            color: #4a4a4a;
            font-family: "Montserrat";
            font-size: 15px;
            font-weight: 600;
        }
        .line-breaker-full{
            width: 100%;
            height: 2px;
            background: #999999;
            margin: 24px 0;
        }
        .line-breaker {
            margin: 10px 0;
            text-align: center;
            position: relative;
            font-size: 17px;
            &:after,&:before {
                content: "";
                display: block;
                position: absolute;
                width: ~"calc(50% - 25px)";
                height: 2px;
                background-color: #999999;
                top: 50%;
            }
            &:before {
                left: 0;
            }
            &:after {
                right: 0;
            }
        }
        .sign-in{
            padding: 43px 60px 0 60px;
            position: inherit;
            width:100%;
            top:58px;
           
            .supplier-button{
                width: 100%;
                height: 50px;
            }
            .login-step2-title{
                font-family: "Montserrat";
                font-size: 15px;
                font-weight: 600;
                text-align: center;
                .facebook{
                    cursor: pointer;
                    color: #2d2d94
                }
                .google{
                    cursor: pointer;
                    color:#3d3d9e;
                }
            }
            input{
                &::placeholder{
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 600;
                    color: #4a4a4a;
                }  
            }
            .forgot-pass{
                font-family: "Montserrat";
                font-size: 15px;
                font-weight: 500;
                color: #000000;
                text-decoration: underline;
                display: block;
                margin-bottom: 20px;
            }
            .login-title{
                text-align: center;
                font-family: "Montserrat";
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 21px;
            }
            
        }
        .register-step2{
            .sign-in;
            .more-validation{
                .break-line{
                    height: 1px;
                    background: #999999;
                    margin: 20px 0;
                }
            }
            .register-step2-title{
                font-family: "Montserrat";
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 39px;
            }
            .phone-number{
                .code{
                    width: 69px;
                }
                .mobile-number{
                    // padding-left:10px;
                }
                &.input-wrapper{
                    padding-left: 0px !important;
                }
            }
            
            .phone-number, .currency, .organisation{
                height: 50px;
                &.add-arrow{
                    color: #4a4a4a;
                }
                select{
                    border: 0;
                    width: 100%;
                    height: 100%;
                    font-family: "Montserrat";
                    font-size: 15px;
                    font-weight: 600;
                    color: #4a4a4a;
                    &.country-code{
                        background-color: rgba(225, 225, 225, 0.33);
                        width:90px;
                    }
                    &.currency-code, &.organisation-type,&.country-code{
                        -webkit-appearance: none;
                    }
                }
                input{
                    border:0;
                    border-left:solid 1px rgba(155, 155, 155, 0.39);
                }
            }
        }
        .sign-up{
            width: 100%;
            display: inline-flex;
            justify-content: space-around;
            margin-bottom:21px;
            align-items: center;
            .sign-up-message{
                margin-bottom: unset;
                font-family: "Montserrat";
                font-size: 12px;
                font-weight: 500;
                max-width: 195px;
            }
            .logsign-input{
                width: auto;
                max-width: 90px;
                .register{
                    font-size: 13px;
                    position: initial;
                    text-align: center;
                    color: #000000;
                    line-height: 44px;
                    padding: 0 16px;
                }
            }  
        }
    }
    
    
    

}
