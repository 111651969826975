.opaque{
    background: white;
}
.header-container{
    padding: 0;
    height:80px;
    width:100%;
    font-size:11px;
    position: fixed;
    top:0;
    left:0;
    z-index:4;
    // background:transparent;
    transition: background 0.3s ease 0.4s !important;
    .header-section{
        height:100%;
        padding: 0 40px;
    }
    .d24-logo{
        width: 60px;
        display: block;
        margin: 0 20px 0 0;
        img{
            width:100%;
        }
    }
    .supplier-button{
        width:200px;
        max-width: 200px;
        font-weight: 500;
    }
    .solid-button{
        color:#fff !important;
        font-family: "Montserrat";
        font-weight: 600;
        font-size:16px;
        text-transform: initial !important
    }
    .header-login{
        // width: auto;
        // color: black;
        // font-size: 13px;
        // padding: 14px 26px;
        // font-size: 14px;
        // font-weight: 500;
        // font-family: "Montserrat";
        &.solid-button{
            margin-left: 17px;
            padding: 0 26px;
            background: transparent;
            color: black !important;
            width: auto;
        }

    }
    
    
}
